<template>
  <div>
    <!--面包屑-->
    <div class="page-breadcrumb">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>活动订单</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <!--内容-->
    <div class="page-content-x">
      <!--搜索-->
      <div v-show="issearch" class="page-content-search">
        <el-form ref="form" :model="search" size="medium" :label-width="this.env.search_label_width">
          <el-row>
            <el-col :span="6">
              <el-form-item label="活动名称">
                <el-input v-model="search.activity_title"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="用户昵称">
                <el-input v-model="search.user_nick"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="用户手机号">
                <el-input v-model="search.phone"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="订单号">
                <el-input v-model="search.order_no"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="签到状态">
                <el-select v-model="search.is_sign_ok" placeholder="请选择状态" style="width: 100%">
                  <el-option label="全部" value=""></el-option>
                  <el-option label="已签到" :value="1"></el-option>
                  <el-option label="未签到" :value="2"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="退款状态">
                <el-select v-model="search.refund_state_ok" placeholder="请选择状态" style="width: 100%">
                  <el-option label="全部" value=""></el-option>
                  <el-option label="有退款" :value="1"></el-option>
                  <el-option label="无退款" :value="2"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="page-content-search-button">
            <el-col :span="24">
              <el-form-item>
                <el-button icon="el-icon-search" size="medium" type="primary" @click="is_search">搜索
                </el-button>
                <el-button icon="el-icon-circle-close" size="medium" @click="init">清空搜索</el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <!-- 标题 -->
      <el-row>
        <el-col :span="12">
          <h3>{{ page_name }}</h3>
        </el-col>
        <el-col :span="12">
          <div class="top-operation-button">
            <el-button v-if="issearch" icon="el-icon-caret-top" size="medium" type="primary"
              @click="issearch = !issearch">隐藏搜索
            </el-button>
            <el-button v-else icon="el-icon-caret-bottom" size="medium" type="primary" @click="issearch = !issearch">显示搜索
            </el-button>
            <el-button icon="el-icon-download" size="medium" type="primary" @click="exportOrder">导出
            </el-button>
          </div>
        </el-col>
      </el-row>
      <div style="height: 20px"></div>
      <!--列表-->
      <el-table size="medium" border v-loading="loading" :data="tableData" style="width: 100%">
        <el-table-column prop="activity_title" label="活动名称" width="120" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="title" label="产品规格" width="80" show-overflow-tooltip>
          <template slot-scope="scope">
            <span v-if="!is_empty(scope.row.sku_info)">{{ scope.row.sku_info.sku_name }}</span>
            <span v-else>-</span>
          </template>
        </el-table-column>
        <el-table-column prop="user_nick" label="用户名称" width="120" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="phone" label="手机号" width="120" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="order_no" label="订单号" width="120" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="create_time" label="创建时间" width="120" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="is_sign" label="是否签到">
          <template slot-scope="scope">
            <el-tag size="mini" v-if="scope.row.is_sign > 0">已签到</el-tag>
            <el-tag size="mini" v-else type="info">未签到</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="phone" label="是否退款" width="200">
          <template slot-scope="scope">
            <el-popover v-if="scope.row.refund_state === 1" placement="right" width="300" trigger="click"
              show-overflow-tooltip>
              <div style="line-height: 30px">
                <div>用户名称：{{ scope.row.user_nick }}</div>
                <div>手机号码：{{ scope.row.phone }}</div>
                <div>订单号：{{ scope.row.order_no }}</div>
                <div>退款时间：{{ scope.row.refund_time }}</div>
                <div>退款备注：{{ scope.row.refund_remarks }}</div>
              </div>
              <el-tag size="mini" type="danger" slot="reference">有退款</el-tag>
            </el-popover>
            <el-tag size="mini" v-else type="info">无退款</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="pay_time" label="支付时间" width="120" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.pay_time === "1970-01-01 08:00" ? "无" : scope.row.pay_time }}
          </template>
        </el-table-column>
        <el-table-column prop="source_channel" label="是否团购">
          <template slot-scope="scope">
            <span v-if="scope.row.source_channel === 0">否</span>
            <span v-if="scope.row.source_channel === 1">是</span>
          </template>
        </el-table-column>
      </el-table>
      <div style="height: 20px"></div>
      <el-pagination @current-change="getlist" :page-size="this.env.pageSize" :pager-count="7" background
        layout="prev, pager, next, total" :current-page.sync="page" :total="count">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: "OrderList",
  data() {
    return {
      page_name: "活动订单",
      loading: false, // 加载状态
      issearch: true, // 搜索是否展示
      count: 0, // 数据总条数
      page: 1, // 当前页数
      tableData: [], // 添加页面数据
      search: {}, // 搜索内容
    };
  },
  // 创建
  created() {
    this.init();
  },
  methods: {
    is_empty(data) {
      return this.Tool.is_empty(data)
    },
    init() {
      this.count = 0;
      this.tableData = [];

      this.search = {
        user_nick: "",
        phone: "",
        order_no: "",
      };
      this.is_search();
    },
    is_search() {
      this.page = 1;
      this.getlist();
    },
    // 权限判断
    is_auth(auth) {
      return this.Tool.is_auth(auth);
    },
    // 获取可选教练列表
    getlist() {
      let postdata = {
        api_name: "order.activity.getlist",
        token: this.Tool.get_l_cache("token"),
        pagesize: this.env.pageSize,
        page: this.page,
      };
      // 非必须参数（搜索条件）
      Object.assign(postdata, this.search);
      this.loading = true;
      this.Tool.post_data("merchant", postdata, (json) => {
        this.loading = false;
        if (json.code === 0) {
          this.tableData = json.data.list;
          this.count = json.data.count;
        } else {
          this.tableData = [];
          this.count = 0;
          this.Tool.errormes(json);
        }
      });
    },
    exportOrder() {
      let postdata = {
        api_name: "order.activity.getlist",
        token: this.Tool.get_l_cache("token"),
        pagesize: this.env.pageSize,
        page: this.page,
        export: "yes"
      };
      // 非必须参数（搜索条件）
      Object.assign(postdata, this.search);
      this.loading = true;
      this.Tool.post_data("merchant", postdata, (res) => {
        this.loading = false;
        if (res.code === 10005) {
          this.Tool.errormes(res);
        } else {
          location.href = res;
        }
      });
    },
  },
};
</script>
